<template>
  <div class="d-flex flex-column flex-lg-row">
    <div class="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
      <div class="card">
        <div class="card-header border-0 pt-6">
          <div class="card-title">
            <h4>Search</h4>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="row mb-7">
            <div class="col">
              <!--begin::Label-->
              <label class="fs-6 fw-bold mb-2">Company Name</label>
              <!--end::Label-->
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="appName"
                placeholder=""
                @change="(event) => setSearchProperty(event, 'company')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="appName"
              />
            </div>
          </div>
          <div class="row mb-7">
            <div class="col">
              <!--begin::Label-->
              <label class="fs-6 fw-bold mb-2">Total Employees</label>
              <!--end::Label-->
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="appName"
                placeholder=""
                @change="(event) => setSearchProperty(event, 'employees_on_site')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="appName"
              />
            </div>
          </div>

          <div class="row mb-7">
            <div class="col">
              <label class="fs-6 fw-bold mb-2">Contact Person</label>
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="name"
                placeholder=""
                @change="(event) => setSearchProperty(event, 'name')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="name"
              />
            </div>
          </div>

          <div class="row mb-7">
            <div class="col">
              <label class="fs-6 fw-bold mb-2">Job Title</label>
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="job_title"
                placeholder=""
                @change="(event) => setSearchProperty(event, 'title_full')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="job_title"
              />
            </div>
          </div>
          <div class="row mb-7">
            <div class="col">
              <label class="fs-6 fw-bold mb-2">City</label>
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="city"
                placeholder=""
                @change="(event) => setSearchProperty(event, 'city')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="city"
              />
            </div>
          </div>
          <div class="row mb-7">
            <div class="col">
              <label class="fs-6 fw-bold mb-2">State</label>
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="state"
                placeholder=""
                @change="(event) => setSearchProperty(event, 'state')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="state"
              />
            </div>
          </div>
          <div class="row mb-7">

            <div class="col">
              <label class="fs-6 fw-bold mb-2">Zip</label>
              <Field
                type="text"
                class="form-control form-control-lg form-control-solid"
                name="zip"
                placeholder=""
                @change="(event) => setSearchProperty(event, 'zip')"
              />
              <ErrorMessage
                class="fv-plugins-message-container invalid-feedback"
                name="zip"
              />
            </div>
          </div>
          <div class="row mb-7">
            <div class="col">
              <button
                type="button"
                class="btn btn-primary me-10"
                id="kt_button_1"
                :data-kt-indicator="isLoading"
              >
                <span class="indicator-label" @click="search">Search</span>
                <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10">
      <div class="card" v-if="businesses.length">
        <div class="card-header border-0 pt-6">
          <!--begin::Card title-->
          <div class="card-title">
            <h4>Results ({{ total }})</h4>
          </div>
          <!--begin::Card title-->
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div
              v-if="selectedBusiness.length > 0"
              class="d-flex justify-content-end"
              data-kt-customer-table-toolbar="base"
            >
              <!--begin::Export-->
              <button
                type="button"
                class="btn btn-light-primary me-3"
                data-bs-toggle="modal"
                data-bs-target="#kt_customers_export_modal"
              >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
                Export
              </button>
              <!--end::Export-->
              <!--begin::Add to List-->
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_add_to_list"
              >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotone/Navigation/Plus.svg" />
            </span>
                Add to List
              </button>
              <!--end::Add to List-->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <div class="card-body pt-0">
          <Datatable
            v-if="businesses.length"
            :table-data="businesses"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            :pageChange="pageChange"
            :rows-per-page-change="rowsPerPageChange"
            :sort="sort"
            :total="total"
          >
            <template v-slot:cell-checkbox="{ row: customer }">
              <div
                class="form-check form-check-sm form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  @change="event => checkRows(event, customer.id)"
                />
              </div>
            </template>
            <template v-slot:cell-name="{ row: customer }">
              {{ customer.name }}
            </template>
            <template v-slot:cell-email="{ row: customer }">
              <a href="#" class="text-gray-600 text-hover-primary mb-1">
                {{ customer.email }}
              </a>
            </template>
            <template v-slot:cell-company="{ row: customer }">
              {{ customer.company }}
            </template>
            <template v-slot:cell-total_employees_corp_wide="{ row: customer }">
              {{ customer.total_employees_corp_wide.replace(/^0+/, "") }}
            </template>

            <template v-slot:cell-paymentMethod="{ row: customer }">
              <img :src="customer.payment.icon" class="w-35px me-3" alt="" />{{
                customer.payment.ccnumber
              }}
            </template>
            <template v-slot:cell-date="{ row: customer }">
              {{ customer.date }}
            </template>
            <template>
              <a
                href="#"
                class="btn btn-sm btn-light btn-active-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
              >Actions
                <span class="svg-icon svg-icon-5 m-0">
              <inline-svg src="media/icons/duotune/arrows/arr072.svg" />
            </span>
              </a>
              <!--begin::Menu-->
              <div
                class="
              menu
              menu-sub
              menu-sub-dropdown
              menu-column
              menu-rounded
              menu-gray-600
              menu-state-bg-light-primary
              fw-bold
              fs-7
              w-125px
              py-4
            "
                data-kt-menu="true"
              >
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <router-link
                    to="/apps/customers/customer-details"
                    class="menu-link px-3"
                  >View
                  </router-link>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a class="menu-link px-3">
                    Delete
                  </a>
                </div>
                <!--end::Menu item-->
              </div>
              <!--end::Menu-->
            </template>
          </Datatable>
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-header border-0 pt-6">
          <!--begin::Card title-->
          <div class="card-title">
            <h4>No Results</h4>
          </div>
          <!--begin::Card title-->
        </div>
        <div class="card-body pt-0">
        </div>
      </div>
    </div>
  </div>

  <ExportCustomerModal></ExportCustomerModal>
  <AddCustomerModal></AddCustomerModal>
  <AddToList list-type="Business"></AddToList>
</template>

<script>
import { ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field, ErrorMessage } from "vee-validate";
import store from "@/store";
import AddToList from "@/views/search/business/AddToList";

export default {
  name: "Business",
  components: {
    AddToList,
    Datatable,
    ExportCustomerModal,
    AddCustomerModal,
    Field,
    ErrorMessage
  },
  computed: {
    businesses() {
      return store.getters["Business/getRecords"];
    },
    getSearchValues() {
      return store.getters["Business/getSearchValues"];
    },
    selectedBusiness() {
      return store.getters["Business/getSelectedBusiness"];
    },
    total() {
      return store.getters["Business/getTotal"];
    }
  },
  watch: {
    pagination: {
      handler() {
        this.search();
      },
      deep: true
    }
  },
  data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 15,
        total: 0
      },
      isLoading: "off",
      checkedRows: []
    };
  },
  mounted() {
    store.dispatch("setBreadcrumbAction", { title: "Businesses" });
  },
  methods: {
    setSearchProperty(event, field) {
      store.dispatch("Business/setSearchProperty", { field, value: event.target.value });
    },
    search() {
      this.checkedRows = [];
      this.loading = true;
      this.isLoading = "on";
      const search = { ...this.getSearchValues, ...this.pagination };
      const queryString = Object.keys(search).map(key => key + "=" + search[key]).join("&");
      store.dispatch("Business/search", queryString)
        .then(() => (this.isLoading = "off"));
    },
    serialize(obj) {
      const str = [];
      for (const p in obj) {
        //if (obj.hasOwnProperty(p)) {
        str.push(`${ encodeURIComponent(p) }=${ encodeURIComponent(obj[p]) }`);
        //}
      }
      return str.join("&");
    },
    pageChange(page) {
      this.pagination.page = page;
    },
    rowsPerPageChange(rows) {
      this.pagination.rowsPerPage = rows;
    },
    checkRows(event, id) {
      store.dispatch("Business/selectBusiness", { id, checked: event.target.checked });
    },
    sort(data) {
      console.log(data);
    }
  },
  setup() {
    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox"
      },
      {
        name: "Name",
        key: "name",
        sortable: true
      },
      {
        name: "Email",
        key: "email",
        sortable: true
      },
      {
        name: "Company",
        key: "company",
        sortable: true
      },
      {
        name: "Position",
        key: "title_full"
      },
      {
        name: "Industry",
        key: "category",
        sortable: true
      },
      {
        name: "City",
        key: "city",
        sortable: true
      },
      {
        name: "State",
        key: "state",
        sortable: true
      },
      {
        name: "Zip",
        key: "zip",
        sortable: true
      },
      {
        name: "Country",
        key: "country",
        sortable: true
      },
      {
        name: "Employees",
        key: "total_employees_corp_wide",
        sortable: true
      }
    ]);
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Customers Listing", ["Apps", "Customers"]);
    });

    return {
      tableHeader,
      checkedCustomers
    };
  }
};
</script>

<style scoped></style>
