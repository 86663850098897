<template>
  <div
    class="modal fade"
    id="kt_modal_add_to_list"
    ref="addCustomerModalRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_add_customer_header">
          <!--begin::Modal title-->
          <h2 class="fw-bolder">Add to List</h2>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div
            id="kt_modal_add_customer_close"
            data-bs-dismiss="modal"
            class="btn btn-icon btn-sm btn-active-icon-primary"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Form-->
        <el-form
          :rules="rules"
          ref="formRef"
        >
          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <ul class="nav nav-tabs nav-line-tabs mb-5 fs-5">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#available_lists"
                >
                  Available Lists
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#create_list"
                >
                  Create List
                </a>
              </li>
            </ul>

            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="available_lists"
                role="tabpanel"
              >
                <!--begin::Available Lists-->
                <select
                  @change="event => selectedList(event)"
                  class="form-select form-select-solid"
                >
                  <option value="">Select a List</option>
                  <option :value="value.id"
                          v-for="value in lists"
                          :key="value.id"
                  >{{ value.name }}
                  </option>
                </select>
                <!--end::Available Lists-->
                <div class="row mt-2 d-flex flex-row-reverse">
                  <button
                    class="btn btn-lg btn-primary btn-sm col-2"
                    type="button"
                    @click="event => addToList(event)"
                  >
                    <span class="indicator-label">
                      Save
                      <span class="svg-icon svg-icon-3 ms-2 me-0">
                        <inline-svg src="icons/duotune/arrows/arr064.svg" />
                      </span>
                    </span>
                    <span class="indicator-progress">
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2">
                      </span>
                    </span>
                  </button>
                </div>
              </div>

              <div class="tab-pane fade" id="create_list" role="tabpanel">
                <div class="row">
                  <Field
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    name="list_name"
                    placeholder="List Name"
                    v-model="list_name"
                  />
                  <ErrorMessage
                    class="fv-plugins-message-container invalid-feedback"
                    name="list_name"
                  />
                </div>
                <div class="row mt-2 d-flex flex-row-reverse">
                  <button
                    class="btn btn-lg btn-primary btn-sm col-2"
                    type="button"
                    @click="createList"
                  >
                    <span class="indicator-label">
                      Create
                      <span class="svg-icon svg-icon-3 ms-2 me-0">
                        <inline-svg src="icons/duotune/arrows/arr064.svg" />
                      </span>
                    </span>
                    <span class="indicator-progress">
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2">
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->
        </el-form>
        <!--end::Form-->
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import store from "@/store";

export default {
  name: "AddToList",
  props: {
    listType: {
      validator: function(value) {
        // The value must start with capital letter
        return value.charAt(0) === value.charAt(0).toUpperCase();
      }
    }
  },
  data() {
    return {
      list_name: "",
      selected_list: ""
    };
  },
  computed: {
    lists() {
      return store.getters[`ListModule/get${ this.listType }List`];
    },
    selectedBusiness() {
      return store.getters["Business/getSelectedBusiness"];
    },
    selectedPeople() {
      return store.getters["People/getSelectedPeople"];
    }
  },
  methods: {
    getList() {
      store.dispatch(`ListModule/get${ this.listType }List`);
    },
    addToList() {
      if (this.selected_list !== "") {
        store.dispatch("ListModule/addToList", {
          rows: this[`selected${ this.listType }`],
          list_id: this.selected_list,
          list_type: this.listType
        }).then(() => {
          this.showSuccessToast("Successfully added to the list");
        }).catch(() => {
          this.showErrorToast("Error!");
        });
      } else {
        this.showErrorToast("Please select a list");
      }
    },
    createList() {
      store.dispatch(`ListModule/create${ this.listType }List`, { name: this.list_name })
        .then(() => {
          this.showSuccessToast("List created successfully");
        }).catch(() => {
        this.showErrorToast("Error!");
      });
    },
    selectedList(event) {
      this.selected_list = event.target.value;
    },
    showSuccessToast(message) {
      store.dispatch("NotificationModule/setNotification", {
        message: message,
        type: "success"
      });
    },
    showErrorToast(message) {
      store.dispatch("NotificationModule/setNotification", {
        message: message,
        type: "error"
      });
    }
  },
  mounted() {
    this.getList();
  },
  components: {
    Field,
    ErrorMessage
  }
};
</script>

<style scoped>

</style>